.row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 40px;
}
.codeContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  width: 120px;
  .title {
    width: fit-content;
    color: #fff;
    font-family: HarmonyOS Sans SC;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
  .code {
    width: 120px;
    height: 120px;
  }
  .subTitle {
    margin-top: -6px;
    text-align: center;
    color: #fff;
    font-family: HarmonyOS Sans SC;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    opacity: 0.8;
  }
}
