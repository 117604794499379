@mixin navigation-base($bg, $color) {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 56px;
  padding: 0 80px;
  background: $bg;
  transition: all 0.3s;
  z-index: 99;
  .logo {
    width: 96px;
    height: 28px;
  }
  .right {
    display: flex;
    gap: 40px;
    a {
      text-decoration: none;
      display: flex;
      align-items: center;
      gap: 4px;
    }
  }
  .menu {
    padding: 18px 10px;
    position: relative;

    color: $color;
    /* 14M */
    font-family: HarmonyOS Sans SC, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */

    &::after {
      content: '';
      width: 0;
      height: 2px;
      background: #47b2ff;
      position: absolute;
      bottom: 0;
      left: 0;
      transition: all 0.3s;
    }
    &:hover {
      color: #47b2ff;

      &::after {
        width: 100%;
      }
      .menu-child {
        opacity: 1;
        height: fit-content;
        padding: 16px;
      }
    }
  }
  .link-btn {
    &::before {
      content: '';
      position: absolute;
      inset: -20px;
    }
  }
  .menu-child {
    opacity: 0;
    height: 0;
    padding: 0 16px;
    position: absolute;
    min-width: 174px;
    display: flex;
    gap: 8px;
    flex-direction: column;
    border-radius: var(--6, 6px);
    box-shadow: 0px 4px 20px 0px rgba(153, 153, 153, 0.1);
    transform: translate(-20px, 100%);
    bottom: -20px;
    transition: all 0.3s;
    overflow: hidden;
  }
  .menu-child-btn {
    flex: 1;
    padding: 3px 8px;
    color: var(--222222, #222);
    /* 14M */
    font-family: HarmonyOS Sans SC, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    white-space: nowrap;

    &:hover {
      color: #47b2ff;
    }
  }
  .nav-checked {
    color: #47b2ff;
    &::after {
      width: 100%;
    }
  }

  @media (min-width: 1600px) {
    padding: 0 120px;
  }
}

.navigation-transparent {
  @include navigation-base(transparent, #ffffff);
}

.navigation-white {
  @include navigation-base(transparent, #222222);
}
