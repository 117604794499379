@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.filter-bg {
  position: absolute;
  inset: 0;
  overflow: hidden;
  pointer-events: none;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 4px 20px 0px rgba(153, 153, 153, 0.1);
  backdrop-filter: blur(20px);
  z-index: -1;
  border-radius: 6px;
}

.concat-us-entry-container {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  pointer-events: none;
  z-index: 100;
}

.concat-us-entry {
  width: 64px;
  height: 64px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: -2px;
  position: absolute;
  right: 20px;
  bottom: 50%;
  border-radius: var(--6, 6px);
  background: var(--47-b-2-ff, #47b2ff);
  cursor: pointer;
  pointer-events: auto;
  z-index: 5;
  p {
    color: var(--ffffff, #fff);
    font-family: HarmonyOS Sans SC;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
  }
}

.concat-form {
  width: 456px;
  min-height: 542px;
  position: absolute;
  right: 20px;
  bottom: calc(50% - 270px);
  transition: all 0.5s ease;
  transform: translateX(500px);
  opacity: 0;
  border-radius: var(--6, 6px);
  z-index: 10;
  padding: 26px 28px 8px 28px;
  pointer-events: auto;
  display: flex;
  flex-direction: column;
  .concat-form-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    span {
      color: var(--222222, #222);
      font-family: HarmonyOS Sans SC;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
    }
    svg {
      cursor: pointer;
    }
  }
  .concat-form-content {
    margin-top: 20px;

    .error-style {
      color: var(--ff-4-a-4-a, #ff4a4a);
      text-align: right;
      font-family: HarmonyOS Sans SC;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
    }
    :global {
      .ant-form-item-label {
        padding-bottom: 4px !important;
      }
      label {
        color: var(--222222, #222);
        /* 14R */
        font-family: HarmonyOS Sans SC;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }
      .ant-form-item {
        margin-bottom: 20px;
      }
      .ant-input {
        border-color: rgba(34, 34, 34, 0.05);
        padding: 6px 8px;
        font-family: HarmonyOS Sans SC;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }
      .ant-select-selector {
        border-color: rgba(34, 34, 34, 0.05) !important;
      }

      .ant-col-12 {
        flex: 0 0 calc(50% - 10px);
      }
      .ant-row {
        column-gap: 20px;
      }
      .ant-btn-primary {
        width: 100%;
        height: 36px;
        margin-top: 20px;
      }
      .ant-input-affix-wrapper {
        border-width: 0;
      }
      .ant-input-affix-wrapper-focused {
        box-shadow: 0 0 0 2px rgba(5, 188, 255, 0.06);
      }
      .ant-input-suffix {
        margin-bottom: 20px;
      }
      .ant-form-item-explain {
        line-height: 20px;
      }
    }
  }
  .success-result {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
  }
  .code-modal {
    position: absolute;
    left: -170px;
    top: 0;
    width: 160px;
    height: 212px;
    border-radius: var(--6, 6px);
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
    opacity: 1;
    p {
      color: rgba(34, 34, 34, 0.8);
      font-family: HarmonyOS Sans SC;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      white-space: pre-wrap;
    }
    img {
      width: 108px;
      height: 108px;
    }
  }
  .code-modal-animation {
    // animation: name duration timing-function delay iteration-count direction fill-mode;
    animation: fadeIn 1s ease-in;
  }
}
