.textContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  color: #fff;
  font-family: HarmonyOS Sans SC;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  .logo {
    width: 192px;
    height: 56px;
  }
}

.actionsConainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  color: #fff;
  min-width: 192px;
  .actionText {
    &:hover {
      opacity: 1;
    }
    font-family: HarmonyOS Sans SC;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    opacity: 0.8;
    cursor: pointer;
  }
}

.linkBtn {
  background: var(--47-b-2-ff, #47b2ff);
  border-radius: var(--6, 6px);
  color: #fff;
  font-size: 12px;
  padding: 2px 8px;
  margin-left: 6px;
  &:hover {
    background: linear-gradient(0deg, rgba(34, 34, 34, 0.1) 0%, rgba(34, 34, 34, 0.1) 100%), #47b2ff;
    color: #fff;
  }
}
