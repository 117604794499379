.copyRight {
  border-top: rgba($color: #fff, $alpha: 0.2);
  border-top-width: 2px;
  width: 100%;
  min-width: 380px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  .line {
    width: 100%;
    height: 1px;
    background: rgba($color: #fff, $alpha: 0.2);
  }
  .textRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 16px;
    .icp {
      font-family: HarmonyOS Sans SC;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      color: rgba($color: #fff, $alpha: 0.5);

      cursor: pointer;
      &:hover {
        color: rgba($color: #fff, $alpha: 1);
      }
    }
    .police {
      display: flex;
      flex-direction: row;
      gap: 6px;
      .icon {
        width: 14px;
        height: 14px;
      }
      .text {
        font-family: HarmonyOS Sans SC;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        color: rgba($color: #fff, $alpha: 0.5);

        cursor: pointer;
        &:hover {
          color: rgba($color: #fff, $alpha: 1);
        }
      }
    }
  }
}
