.footer {
  width: 100%;
  min-width: 1200px;
  height: 404px;
  padding-block: 60px;
  margin: 0 auto;
  background: #222222;
  color: #fff;
  display: flex;
  flex-direction: column;
  gap: 40px;
  .content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    width: 100%;
    height: 100%;
    color: #fff;
  }
}

@media screen and (max-width: 1600px) {
  .footer {
    padding-inline: 80px;
  }
}

// @media screen and (min-width: 1201px) {
//   .footer {
//     padding-inline: 120px;
//   }
// }

@media screen and (min-width: 1601px) {
  .footer {
    padding-inline: 120px;
  }
}
