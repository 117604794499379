@import '@idr/scss-vars/dist/idr.css';
@import './animations.css';

@font-face {
  font-family: 'Smiley Sans';
  src: url('https://static.neural-avatar.com/font/SmileySans-Oblique.otf');
}

@font-face {
  font-family: 'HarmonyOS Sans SC';
  font-weight: 500;
  src: url('https://static.neural-avatar.com/font/HarmonyOS_Sans_SC_Medium.ttf');
}

@font-face {
  font-family: 'HarmonyOS Sans SC';
  font-weight: 400;
  src: url('https://static.neural-avatar.com/font/HarmonyOS_Sans_SC_Regular.ttf');
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  padding: 0;
  margin: 0;
  line-height: 1.6;
  font-size: 18px;
  font-family: HarmonyOS Sans SC, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell,
    Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

/*@media (prefers-color-scheme: dark) {*/
/*  html {*/
/*    color-scheme: dark;*/
/*  }*/
/*}*/

a {
  color: inherit;
  text-decoration: none;
}

img {
  max-width: 100%;
  display: block;
}

h1 {
  margin: 0;
}

.global-filter-bg {
  position: absolute;
  inset: 0;
  overflow: hidden;
  pointer-events: none;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 4px 20px 0px rgba(153, 153, 153, 0.1);
  backdrop-filter: blur(20px);
  z-index: -1;
}
